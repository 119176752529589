import { FunctionComponent } from "react";

export type ChipProps = {
    title: string,
    isSelected: boolean,
    onClick: () => void,
}

export const Chip: FunctionComponent<ChipProps> = ({ title, isSelected, onClick }) => (
    <button
        className={`chip ${isSelected ? "chip--selected" : ""}`}
        onClick={() => onClick()}
    >
        {title}
    </button>
);
