import { useState } from "react";

import { Chip, ListItem } from "./components";
import { itemList, tagList } from "./constants";
import { TagType } from "./types";
import "./App.scss";

export const App = () => {
    const [selectedTagType, setSelectedTagType] = useState<TagType | undefined>(undefined);

    return (
        <div className="App">
            <h1>Návody</h1>
            <Chip
                title="Vše"
                isSelected={selectedTagType === undefined}
                onClick={() => setSelectedTagType(undefined)}
            />
            {tagList
                .sort((a, b) => a.title.localeCompare(b.title))
                .map(tag => (
                    <Chip
                        key={tag.type}
                        title={tag.title}
                        isSelected={tag.type === selectedTagType}
                        onClick={() => setSelectedTagType(tag.type)}
                    />
                ))
            }
            {itemList
                .filter(item => selectedTagType === undefined ? true : item.tagTypeList.includes(selectedTagType))
                .map(item => (
                    <ListItem
                        key={item.title}
                        {...{ ...item }}
                        onSelectTag={(tagType) => setSelectedTagType(tagType)}
                    />
                ))
            }
        </div>
    );
};
