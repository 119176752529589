import { FunctionComponent, ReactElement } from "react";

import { tagList } from "../../constants";
import { TagType } from "../../types";
import { Chip } from "../Chip";

export type ListItemProps = {
    title: String;
    tagTypeList: TagType[];
    content: ReactElement;
    onSelectTag: (tagType: TagType) => void;
}

export const ListItem: FunctionComponent<ListItemProps> = ({
    title,
    content,
    tagTypeList,
    onSelectTag,
}) => (
    <div>
        <h2>{title}</h2>
        <div style={{ paddingBottom: 10 }}>
            {content}
        </div>
        <div>{tagList
            .filter((tag) => tagTypeList.includes(tag.type))
            .sort((a, b) => a.title.localeCompare(b.title))
            .map(tag => (
                <Chip
                    key={tag.type}
                    title={tag.title}
                    isSelected={false}
                    onClick={() => onSelectTag(tag.type)}
                />
            ))
        }</div>
    </div>
);
