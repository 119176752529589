export enum TagType {
    Android,
    Windows,
    Multimedia,
    Development,
    Lifestyle,
    Work,
    Administration,
    AndroidStudio,
    MacOS,
}
