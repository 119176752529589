import { stringToTimestamp } from "./utils";
import { TagType, Item, Tag } from "./types";

export const tagList: Tag[] = [
    {
        type: TagType.Administration,
        title: "Administrace",
    },
    {
        type: TagType.Android,
        title: "Android",
    },
    {
        type: TagType.AndroidStudio,
        title: "Android Studio",
    },
    {
        type: TagType.Development,
        title: "Vývoj",
    },
    {
        type: TagType.Lifestyle,
        title: "Životní styl",
    },
    {
        type: TagType.Multimedia,
        title: "Multimedia",
    },
    {
        type: TagType.Windows,
        title: "Windows",
    },
    {
        type: TagType.Work,
        title: "Práce",
    },
    {
        type: TagType.MacOS,
        title: "macOS",
    },
];

export const itemList: Item[] = [
    {
        title: "Globální proměnné",
        tagTypeList: [TagType.Development, TagType.MacOS],
        content: (
            <ul>
                <li>Vytvořit soubor <code>touch ~/.zprofile</code></li>
                <li>Otevřít soubor <code>open ~/.zprofile</code></li>
                <li>Přidat na konec souboru proměnné ve tvaru <code>export KEY=VALUE</code></li>
                <li>Uložit soubor</li>
                <li>Znovu otevřít terminál</li>
                <li>Proměnné je možné vypsat příkazem<code>printenv</code></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("28-11-2023"),
    },
    {
        title: "Deobfuskace",
        tagTypeList: [TagType.Android, TagType.Development],
        content: (
            <div>
                <ul>
                    <li>Otevřít umístění instalace Java <code>cd C:\Program Files\Android\Android Studio Bumblebee\jre\bin</code></li>
                    <li>Spustit <b>ProGuard</b> <code>java -jar C:\Users\[USER]\AppData\Local\Android\Sdk\tools\proguard\lib\proguardgui.jar</code></li>
                    <li>Vybrat možnost <b>ReTrace</b></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("19-07-2022"),
    },
    {
        title: "Zvýšení maximální hlasitosti",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <div>
                <ul>
                    <li>Stáhnout <a href="https://equalizerapo.com/download.html">APO Equalizer</a></li>
                    <li>Nainstalovat</li>
                    <li>Změnit obsah souboru <b>config/config.txt</b> na <code>Preamp: 20 dB</code></li>
                    <li>Spustit <b>Configurator.exe</b></li>
                    <li>Pro používaná zařízení zapnout experimentální <b>Install as SFX/EFX</b></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("28-06-2022"),
    },
    {
        title: "Zrychlení videa",
        tagTypeList: [TagType.Multimedia],
        content: (
            <div>
                <ul>
                    <li>Stáhnout <a href="https://ffmpeg.org/download.html">ffmpeg</a></li>
                    <li>Spustit příkaz <code>ffmpeg -i input.mp4 -filter:v "setpts=1/ZRYCHLENI*PTS" output.mp4</code></li>
                    <li>Nahrazením <code>ZRYCHLENI</code> za 2 bude video 2× rychlejší</li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("17-05-2022"),
    },
    {
        title: "Git: Ignorování lokálních souborů",
        tagTypeList: [TagType.Development],
        content: (
            <div>
                <p>Přidat cestu k souboru do <b>.git/info/exclude</b></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("14-04-2022"),
    },
    {
        title: "Odstranění vodní značky při exportu videa z Filmora",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <div>
                <ul>
                    <li>Nastavit poměr stran projektu alespoň na 1:1</li>
                    <li>Video umístit na horní okraj plátna</li>
                    <li>Exportovat s vodní značkou</li>
                    <li>Jiným programem oříznout na původní poměr stran</li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("22-02-2022"),
    },
    {
        title: "Zjištění maxima informací o multimediálním souboru",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <div>
                <p>Pomocí programu <b>MediaInfo</b></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("05-02-2022"),
    },
    {
        title: "Změna jemnosti displeje a škálování písma",
        tagTypeList: [TagType.Android],
        content: (
            <div>
                <ul>
                    <li>Jemnost displeje <code>adb shell wm density 2160</code></li>
                    <li>Škálování písma <code>adb shell settings put system font_scale 1.0</code></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("29-01-2024"),
    },
    {
        title: "Navrácení přepínačů internetu na Android 12",
        tagTypeList: [TagType.Android],
        content: (
            <div>
                <ul>
                    <li>Nainstalovat ADB</li>
                    <li><code>adb shell settings put global settings_provider_model false</code></li>
                    <li><code>adb shell settings put secure sysui_qs_tiles "wifi,cell,$(settings get secure sysui_qs_tiles)"</code></li>
                    <li>Restartovat telefon</li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("01-02-2022"),
    },
    {
        title: "Konverze multimediálních souborů",
        tagTypeList: [TagType.Windows],
        content: (
            <div>
                <p>Pomocí programu <b>FormatFactory</b></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("31-01-2022"),
    },
    {
        title: "Přidání SSH klíče pro BitBucket",
        tagTypeList: [TagType.Development, TagType.Windows],
        content: (
            <div>
                <p>Pro odstranění nutnosti přihlašování se skrze přihalšovací údaje je možné využít SSH klíč.</p>
                <ul>
                    <li>Vygenerovat klíč lokálně <code>ssh-keygen</code></li>
                    <li>3× Enter</li>
                    <li>Otestovat pomocí <code>ssh -T git@bitbucket.org</code></li>
                    <li>Přidat klíč na BitBucket</li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("27-01-2022"),
    },
    {
        title: "Globální nastavení pro git",
        tagTypeList: [TagType.Development],
        content: (
            <div>
                <ul>
                    <li><code>git config --global user.name "Sarah Smith"</code></li>
                    <li><code>git config --global user.email "sarah.smith@email.com"</code></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("27-01-2022"),
    },
    {
        title: "Záznam obrazovky ve formě animace",
        tagTypeList: [TagType.Multimedia, TagType.Windows],
        content: (
            <div>
                <p>Pomocí programu <b>ScreenToGif</b></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("25-01-2022"),
    },
    {
        title: "Zobrazení datových typů v Android Studio",
        tagTypeList: [TagType.AndroidStudio, TagType.Windows, TagType.Android],
        content: (
            <div>
                <p>Editor → Inlay Hints → Kotlin → povolit vše</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("21-01-2022"),
    },
    {
        title: "Mobilní telefon jako webkamera",
        tagTypeList: [TagType.Android, TagType.Windows],
        content: (
            <div>
                <p>Mobilní aplikace: <a href="https://play.google.com/store/apps/details?id=com.pas.webcam">Google Play</a></p>
                <p>IP Camera Adapter: <a href="https://ip-webcam.appspot.com/static/doc.html">Dokumentace</a></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("26-02-2021"),
    },
    {
        title: "Průběžný přenos obrazovky telefonu do počítače",
        tagTypeList: [TagType.Android, TagType.Windows],
        content: (
            <div>
                <p>Uložit následující soubor s příponou <b>cmd</b></p>
                <p>
                    <code className="block">
                        cd [složka s ADB]<br />
                        :loop<br />
                        adb shell screencap -p /sdcard/screencap.png && adb pull /sdcard/screencap.png<br />
                        goto loop
                    </code>
                </p>
                <p>U mě je složka s adb <b>pro mě C:\Users\pavel\AppData\Local\Android\Sdk\platform-tools</b></p>
                <p>Obrázek se bude průběžně aktualizovat podle dění na obrazovce</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("09-03-2021"),
    },
    {
        title: "Nesprávné kódování titulků k filmu",
        tagTypeList: [TagType.Windows],
        content: (
            <div>
                <p>Otevřít soubor titulek v prohlížeči Chrome</p>
                <p>Uložit soubor z menu prohlížeče a použít v přehrávači</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("29-03-2021"),
    },
    {
        title: "Převod VOB to MKV",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <div>
                <p>Využít program <b>FFmpeg</b>, podívat se na <a href="https://www.internalpointers.com/post/convert-vob-files-mkv-ffmpeg">návod</a></p>
                <p>Pro spojení na Windows použít příkaz:</p>
                <p><code>type *.VOB &gt; output.vob</code></p>
                <p>Odstranit prokládání příkazem:</p>
                <p><code className="block">FFmpeg\bin\ffmpeg.exe -i VTS_05_1.VOB -vf yadif -codec:v libx264 -crf 21 -codec:a libmp3lame -qscale:a 2 out.mkv</code></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("02-04-2021"),
    },
    {
        title: "Stažení videa z Facebooku",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <div>
                <p>Přidat si v Chrome devtools nové zařízení s rozlišením 240x320 a mobilním typem agenta</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("02-04-2021"),
    },
    {
        title: "Spuštění Chrome bez CORS",
        tagTypeList: [TagType.Windows, TagType.Development],
        content: (
            <div>
                <p>Nastavit cíl spouštění na:</p>
                <p><code>chrome.exe --user-data-dir="C://Chrome dev session" --disable-web-security</code></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("02-04-2021"),
    },
    {
        title: "Pozvolný budík",
        tagTypeList: [TagType.Lifestyle, TagType.Android],
        content: (
            <div>
                <p>Mám rád pozvolné probouzení. A tak jsem si vytvořil zvonění, které nabírá na hlasitosti v průběhu více než 27&nbsp;minut.</p>
                <p>
                    <a href="https://data.pavelpo.cz/navody/Cello%20Alarm.mp3">Zvonění ve formátu MP3</a>
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("22-04-2021"),
    },
    {
        title: "Příprava na vývoj webových aplikací",
        tagTypeList: [TagType.Development, TagType.Windows],
        content: (
            <div>
                <p>Před vývojem je třeba splnit několik požadavků:</p>
                <ul>
                    <li>Instalace <a href="https://nodejs.org/en/">NPM</a> – LTS verze + <i>necessary tools</i></li>
                    <li>Instalace Yarn příkazem <code>npm install --global yarn</code></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("23-04-2021"),
    },
    {
        title: "Příprava na vývoj mobilních aplikací",
        tagTypeList: [TagType.Development, TagType.Windows, TagType.AndroidStudio],
        content: (
            <div>
                <p>Před vývojem je třeba splnit několik požadavků:</p>
                <ul>
                    <li>Přidat systémovou proměnnou <code>JAVA_HOME</code> s cestou <code>C:\Program Files\Android\Android Studio\jre</code></li>
                    <li>Instalace <a href="https://developer.android.com/studio">Android Studio</a></li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("23-04-2021"),
    },
    {
        title: "Aliasy pro příkazový řádek",
        tagTypeList: [TagType.Development, TagType.Windows],
        content: (
            <div>
                <ul>
                    <li>
                        Vytvořit soubor s příponout <code>.cmd</code> a kódováním <code>OEM 852</code> pro podporu českých znaků
                        <pre>{`@echo off
REM clear console (remove e.g. CLink init text)
cls

REM init
cd C:\

echo yarn - i, b, s
doskey i=yarn install
doskey b=yarn run build
doskey s=yarn start

echo projekty - w, a, d
doskey w=cd "C:/Projekty/Web"
doskey a=cd "C:/Projekty/Android"
doskey d=cd "C:/Projekty/Další"

echo git - r
doskey r=git reset HEAD~1`}
                        </pre>
                    </li>
                    <li>Připnout na hlavní panel Příkazový řádek</li>
                    <li>Upravit cíl ikony příkazového řádku na <code>%windir%\system32\cmd.exe /k "[cesta k souboru].cmd"</code></li>
                </ul>
                <p>
                    <b>Troubleshooting:</b> Text scriptu se vypisuje do konzole → soubor nemá správné kódování, lze konvertovat
                    například přes <a href="https://code.visualstudio.com/">Visual Studio Code</a>.
                </p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("25-04-2021"),
    },
    {
        title: "Zpřístupnění ADB v příkazovém řádku",
        tagTypeList: [TagType.Development, TagType.Windows],
        content: (
            <div>
                <ul>
                    <li>
                        Do systémové proměnné <code>PATH</code> přidat cestu
                    </li>
                    <li>
                        Například pro Androdi Studio je to <code>C:\Users\Pavel\AppData\Local\Android\Sdk\platform-tools</code>
                    </li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("08-05-2021"),
    },
    {
        title: "Koho obeznámit při nástupu nebo opuštění zaměstnání?",
        tagTypeList: [TagType.Administration, TagType.Work],
        content: (
            <ul>
                <li>Kontaktovat VZP – při opuštění má ze zákona oznámit zaměstnavatel</li>
                <li>Kontaktovat OSSZ</li>
                <li>Finanční ani živnostenský úřad kontaktovat netřeba</li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("12-05-2021"),
    },
    {
        title: "Ruční řazení fotografií s následným hromadným přejmenováním",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <ul>
                <li>Programem <b>FastStone Image Viewer</b></li>
                <li>Vybrat všechny fotografie</li>
                <li>V kontextovém menu zvolit <b>Přejmenovat</b></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("23-06-2021"),
    },
    {
        title: "Hromadné změny časových značek fotografií",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <ul>
                <li>Programem <b>XnView MP</b></li>
                <li>Vybrat všechny fotografie</li>
                <li>V menu zvolit <b>Nástroje</b> → <b>Změnit časovou značku…</b></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("23-06-2021"),
    },
    {
        title: "Hromadné změny časových značek fotografií i s posunem pro každou fotografii",
        tagTypeList: [TagType.Windows, TagType.Multimedia],
        content: (
            <ul>
                <li>Programem <b><a href="http://www.friedemann-schmidt.com/software/exifer/">Exifer</a></b></li>
                <li>Vybrat všechny fotografie</li>
                <li>V kontextovém menu zvolit <b>EXIF/IPTC</b> → <b>Edit…</b> → <b>EXIF data</b> → <b>Date</b> → <b>Incremental</b></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("23-06-2021"),
    },
    {
        title: "Export databáze do SQL",
        tagTypeList: [TagType.Windows, TagType.Development],
        content: (
            <ul>
                <li>Programem <b><a href="https://sqlitebrowser.org/">DB Browser for SQLite</a></b></li>
                <li>Nahrát databázi skrze <b>Soubor</b> → <b>Otevřít databázi…</b></li>
                <li>Exportovat SQL skrze <b>Soubor</b> → <b>Export</b> → <b>Databáze do souboru SQL…</b></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("06-07-2021"),
    },
    {
        title: "Čas od posledního přihlášení",
        tagTypeList: [TagType.Windows],
        content: (
            <div>
                <p>Spustit přes Příkazový řádek <code>net user %username%</code></p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("22-07-2021"),
    },
    {
        title: "Smazání lokálních větví",
        tagTypeList: [TagType.Development],
        content: (
            <div>
                <p>Spustit přes Příkazový řádek <code>git branch | grep -v '^main$' | xargs git branch -D</code> pro smazání lokálních větví kromě větve <code>main</code></p>
                <p>V případě chybové hlášky <code>xargs: unterminated quote</code> obsahují některé názvy větví nepovolené znaky a je nutné tyto větvě nejprve ručně smazat.</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("16-08-2021"),
    },
    {
        title: "Vytvoření MySQL uživatele",
        tagTypeList: [TagType.Development],
        content: (
            <div>
                <pre>
                    {`CREATE USER 'some_user'@'localhost' IDENTIFIED BY 'user_password';
ALL PRIVILEGES, CREATE, DROP, DELETE, INSERT, SELECT, UPDATE, GRANT OPTION
GRANT ALL PRIVILEGES ON some_domain.* TO 'some_user'@'localhost';`}
                </pre>
            </div>
        ),
        createdTimestamp: stringToTimestamp("02-09-2021"),
    },
    {
        title: "Umístění Outlook maker",
        tagTypeList: [TagType.Development],
        content: (
            <div>
                <p>C:\Users\USER\AppData\Roaming\Microsoft\Outlook\VbaProject.otm</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("16-08-2021"),
    },
    {
        title: "Více výrazů ve vyhledávání Android Studio / IDEA IntelliJ",
        tagTypeList: [TagType.Development, TagType.Android, TagType.AndroidStudio],
        content: (
            <div>
                <p>Upravit File → Settings… → Advanced Settings → Maximum number of results…</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("04-10-2021"),
    },
    {
        title: "Vypnout formátování Markdown souborů",
        tagTypeList: [TagType.Development, TagType.Android, TagType.AndroidStudio],
        content: (
            <div>
                <p>Upravit File → Settings… → Editor → Code style → Formatter → Do not format: → *.md</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("18-09-2022"),
    },
    {
        title: "Oprava internetového připojení ve virtualizovaném macOS",
        tagTypeList: [TagType.MacOS],
        content: (
            <div>
                <p>Nastavit DNS v programu Parallels na 8.8.8.8</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("08-06-2023"),
    },
    {
        title: "Nastavení RAM ve virtualizovaném macOS",
        tagTypeList: [TagType.MacOS],
        content: (
            <ul>
                <li>Paměť RAM na 24 GB <code>prlctl set "macOS 13" --memsize 24576</code></li>
                <li>Počet jader procesoru na 6 <code>prlctl set "macOS 13" --cpus 6</code></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("12-08-2023"),
    },
    {
        title: "Zakázat dočasně uspání sytému",
        tagTypeList: [TagType.MacOS],
        content: (
            <p>Spustit v terminálu příkaz <code>pmset noidle</code></p>
        ),
        createdTimestamp: stringToTimestamp("18-08-2023"),
    },
    {
        title: "Černé pozadí",
        tagTypeList: [TagType.Android, TagType.Multimedia],
        content: (
            <p><a href="./Black background.png">Černý obrázek</a> o velikosti 64×64 pixelů vhodný například pro nastavení nerušivé tapety mobilního telefonu.</p>
        ),
        createdTimestamp: stringToTimestamp("12-09-2023"),
    },
    {
        title: "Export z VectorStock",
        tagTypeList: [TagType.Multimedia],
        content: (
            <ul>
                <li>Importování grafiky z <b>VectorStock</b> může způsobovat vizuální artefakty.</li>
                <li>Řešením může být export v <b>PNG</b> přímo z editoru webové stránky v rozlišení až <b>20 000 × 20 000 pixelů</b>.</li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("23-01-2024"),
    },
    {
        title: "Škálování systémových animací",
        tagTypeList: [TagType.Android],
        content: (
            <ul>
                <li>Měřítko animace okna <code>adb shell settings put global window_animation_scale 1</code></li>
                <li>Měřítko animace přeměny <code>adb shell settings put global transition_animation_scale 1</code></li>
                <li>Měřítko délky animace <code>adb shell settings put global animator_duration_scale 1</code></li>
            </ul>
        ),
        createdTimestamp: stringToTimestamp("29-01-2024"),
    },
    {
        title: "Zvýraznění složek s upravnými soubory",
        tagTypeList: [TagType.AndroidStudio],
        content: (
            <div>
                <p>Version Control → Confirmation → Highlight directories that contain modified files in the Project tree</p>
            </div>
        ),
        createdTimestamp: stringToTimestamp("29-01-2024"),
    },
    {
        title: "Color grading pro Samsung Galaxy S24 LOG záběry v DaVinci Resolve",
        tagTypeList: [TagType.Android, TagType.Multimedia],
        content: (
            <div>
                <ul>
                    <li>Přepnout na záložky <b>Color</b></li>
                    <li>Z <b>Library</b> přetáhnout na záběr <b>Color Space Transformation</b></li>
                    <li>
                        Nastavit hodnoty
                        <ul>
                            <li>Input Color Space: <b>Rec.2100</b></li>
                            <li>Input Gamma: <b>ST2084 4000 nit</b></li>
                            <li>Output Color Space: <b>Rec.709</b></li>
                            <li>Output Gamma: <b>Gamma 2.4</b></li>
                        </ul>
                    </li>
                </ul>
            </div>
        ),
        createdTimestamp: stringToTimestamp("18-10-2024"),
    },
    {
        title: "Zvýraznění cesty upravených souborů",
        tagTypeList: [TagType.AndroidStudio],
        content: (
            <p>Upravit File → Settings… → Version Control → Confirmation → Highlight directories that contain modified files in the Project tree</p>
        ),
        createdTimestamp: stringToTimestamp("22-11-2024"),
    },
].sort((a, b) => b.createdTimestamp - a.createdTimestamp);
